if (typeof $.fn.dataTable != "undefined") {
    /**
     * This plug-in for DataTables represents the ultimate option in extensibility
     * for sorting date / time strings correctly. It uses
     * [Moment.js](http://momentjs.com) to create automatic type detection and
     * sorting plug-ins for DataTables based on a given format. This way, DataTables
     * will automatically detect your temporal information and sort it correctly.
     *
     * For usage instructions, please see the DataTables blog
     * post that [introduces it](//datatables.net/blog/2014-12-18).
     *
     * @name Ultimate Date / Time sorting
     * @summary Sort date and time in any format using Moment.js
     * @author [Allan Jardine](//datatables.net)
     * @depends DataTables 1.10+, Moment.js 1.7+
     *
     * @example
     *    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
     *    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
     *
     *    $('#example').DataTable();
     */
    (function (factory) {
        if (typeof define === "function" && define.amd) {
            define(["jquery", "moment", "datatables"], factory);
        } else {
            factory(jQuery, moment);
        }
    }(function ($, moment) {

        $.fn.dataTable.moment = function (format, locale) {
            var types = $.fn.dataTable.ext.type;

            // Add type detection
            types.detect.unshift(function (d) {
                // Strip HTML tags if possible
                if (d && d.replace) {
                    d = d.replace(/<.*?>/g, '');
                }

                // Null and empty values are acceptable
                if (d === '' || d === null) {
                    return 'moment-' + format;
                }

                return moment(d, format, locale, true).isValid() ?
                    'moment-' + format :
                    null;
            });

            // Add sorting method - use an integer for the sorting
            types.order['moment-' + format + '-pre'] = function (d) {
                if (d && d.replace) {
                    d = d.replace(/<.*?>/g, '');
                }
                return d === '' || d === null ?
                    -Infinity :
                    parseInt(moment(d, format, locale, true).format('x'), 10);
            };
        };

    }));

    /**
     * This pagination plug-in provides pagination controls for DataTables which
     * match the style and interaction of the ExtJS library's grid component.
     *
     *  @name ExtJS style
     *  @summary Pagination in the styling of ExtJS
     *  @author [Zach Curtis](http://zachariahtimothy.wordpress.com/)
     *
     *  @example
     *    $(document).ready(function() {
 *        $('#example').dataTable( {
 *            "sPaginationType": "extStyle"
 *        } );
 *    } );
     */

    $.fn.dataTableExt.oApi.fnExtStylePagingInfo = function (oSettings) {
        return {
            "iStart": oSettings._iDisplayStart,
            "iEnd": oSettings.fnDisplayEnd(),
            "iLength": oSettings._iDisplayLength,
            "iTotal": oSettings.fnRecordsTotal(),
            "iFilteredTotal": oSettings.fnRecordsDisplay(),
            "iPage": oSettings._iDisplayLength === -1 ?
                0 : Math.ceil(oSettings._iDisplayStart / oSettings._iDisplayLength),
            "iTotalPages": oSettings._iDisplayLength === -1 ?
                0 : Math.ceil(oSettings.fnRecordsDisplay() / oSettings._iDisplayLength)
        };
    };

    $.fn.dataTableExt.oPagination.extStyle = {


        "fnInit": function (oSettings, nPaging, fnCallbackDraw) {

            var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

            var ul = $('<ul class="pagination"></ul>');

            var nFirst = $('<li class="paginate_button first"><a href="#" data-dt-idx="0" tabindex="0"><<</a></li>');
            var nPrevious = $('<li class="paginate_button previous"><a href="#" data-dt-idx="0" tabindex="0"><</a></li>');
            var nNext = $('<li class="paginate_button next"><a href="#" data-dt-idx="0" tabindex="0">></a></li>');
            var nLast = $('<li class="paginate_button last"><a href="#" data-dt-idx="0" tabindex="0">>></a></li>');
            var nPageNumBox = $('<li class="paginate_button"><select class="paginate_select select2"><option value="">&nbsp;</option></select><span class="paginate_total"></span></li>');

            ul
                .append(nFirst)
                .append(nPrevious)
                .append(nPageNumBox)
                .append(nNext)
                .append(nLast);


            $(nPaging).append(ul);

            nFirst.find('a').click(function (e) {
                e.preventDefault();

                if ($(this).parent().hasClass("disabled"))
                    return;

                // disabilito i tasti paginazione
                $(nPaging).find(".paginate_button").addClass("disabled");

                oSettings.oApi._fnPageChange(oSettings, "first");
                fnCallbackDraw(oSettings);
            }).bind('selectstart', function () {
                return false;
            });

            nPrevious.find('a').click(function (e) {
                e.preventDefault();

                if ($(this).parent().hasClass("disabled"))
                    return;

                // disabilito i tasti paginazione
                $(nPaging).find(".paginate_button").addClass("disabled");

                oSettings.oApi._fnPageChange(oSettings, "previous");
                fnCallbackDraw(oSettings);
            }).bind('selectstart', function () {
                return false;
            });

            nNext.find('a').click(function (e) {
                e.preventDefault();

                if ($(this).parent().hasClass("disabled"))
                    return;

                // disabilito i tasti paginazione
                $(nPaging).find(".paginate_button").addClass("disabled");

                oSettings.oApi._fnPageChange(oSettings, "next");
                fnCallbackDraw(oSettings);
            }).bind('selectstart', function () {
                return false;
            });

            nLast.find('a').click(function (e) {
                e.preventDefault();
                if ($(this).parent().hasClass("disabled"))
                    return;

                // disabilito i tasti paginazione
                $(nPaging).find(".paginate_button").addClass("disabled");

                oSettings.oApi._fnPageChange(oSettings, "last");
                fnCallbackDraw(oSettings);
            }).bind('selectstart', function () {
                return false;
            });

            nPageNumBox.find('select').change(function () {
                var pageValue = parseInt($(this).val(), 10); // -1 because pages are 0 indexed, but the UI is 1
                var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                if (pageValue < 0) {
                    pageValue = 0;
                } else if (pageValue >= oPaging.iTotalPages) {
                    pageValue = oPaging.iTotalPages - 1;
                }

                // disabilito i tasti paginazione
                $(nPaging).find(".paginate_button").addClass("disabled");

                oSettings.oApi._fnPageChange(oSettings, pageValue);
                fnCallbackDraw(oSettings);
            });

        },


        "fnUpdate": function (oSettings, fnCallbackDraw) {
            if (!oSettings.aanFeatures.p) {
                return;
            }

            var prev, next;

            var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

            /* Loop over each instance of the pager */
            var an = oSettings.aanFeatures.p;

            $(an).find('span.paginate_total').html("/ " + oPaging.iTotalPages);

            var $select = $(an).find('select.paginate_select');
            $select.html("");
            for (var i = 0; i < oPaging.iTotalPages; i++) { //add the pages
                var selected = oPaging.iPage == i ? "selected" : "";
                $select.append('<option value="' + i + '" ' + selected + '>' + (i + 1) + '</option>');
            }

            $(an).each(function (index, item) {

                var $item = $(item);

                if (oPaging.iPage == 0) {
                    prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                    prev.addClass("disabled");
                } else {
                    prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                    prev.removeClass("disabled");
                }

                if (oPaging.iPage + 1 == oPaging.iTotalPages) {
                    next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                    next.addClass("disabled");
                } else {
                    next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                    next.removeClass("disabled");
                }
            });
        }
    };

//
// Pipelining function for DataTables. To be used to the `ajax` option of DataTables
//
    $.fn.dataTable.pipeline = function (opts) {
        // Configuration options
        var conf = $.extend({
            pages: 5,     // number of pages to cache
            url: '',      // script url
            data: null,   // function or object with parameters to send to the server
                          // matching how `ajax.data` works in DataTables
            method: 'GET' // Ajax HTTP method
        }, opts);

        // Private variables for storing the cache
        var cacheLower = -1;
        var cacheUpper = null;
        var cacheLastRequest = null;
        var cacheLastJson = null;

        if (opts.deferLoading) {
            cacheLastJson = opts.initData;
            cacheLower = opts.cacheLower;
            cacheUpper = opts.cacheUpper;
            cacheLastRequest = opts.cacheLastRequest;
        }

        return function (request, drawCallback, settings) {
            var ajax = false;
            var requestStart = request.start;
            var drawStart = request.start;
            var requestLength = request.length;
            var requestEnd = parseInt(requestStart) + parseInt(requestLength);

            if (parseInt(requestLength) == -1) {
                // voglio visualizzare tutti gli elementi, per evitare problemi di visualizzazione forzo la chiamata ajax
                settings.clearCache = true;
            }

            if (settings.clearCache) {
                // API requested that the cache be cleared
                ajax = true;
                settings.clearCache = false;
            } else if (cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper) {
                // outside cached data - need to make a request
                ajax = true;
            } else if (JSON.stringify(request.order) !== JSON.stringify(cacheLastRequest.order) ||
                JSON.stringify(request.columns) !== JSON.stringify(cacheLastRequest.columns) ||
                JSON.stringify(request.search) !== JSON.stringify(cacheLastRequest.search)
            ) {
                // properties changed (ordering, columns, searching)
                ajax = true;
            }

            // Store the request for checking next time around
            cacheLastRequest = $.extend(true, {}, request);

            if (ajax) {
                // Need data from the server
                if (requestStart < cacheLower) {
                    requestStart = requestStart - (requestLength * (conf.pages - 1));

                    if (requestStart < 0) {
                        requestStart = 0;
                    }
                }

                cacheLower = requestStart;
                cacheUpper = requestStart + (requestLength * conf.pages);

                request.start = requestStart;
                request.length = requestLength * conf.pages;

                // Provide the same `data` options as DataTables.
                if ($.isFunction(conf.data)) {
                    // As a function it is executed with the data object as an arg
                    // for manipulation. If an object is returned, it is used as the
                    // data object to submit
                    var d = conf.data(request);
                    if (d) {
                        $.extend(request, d);
                    }
                }
                else if ($.isPlainObject(conf.data)) {
                    // As an object, the data given extends the default
                    $.extend(request, conf.data);
                }

                settings.jqXHR = $.ajax({
                    "type": conf.method,
                    "url": conf.url,
                    "data": request,
                    "dataType": "json",
                    "cache": false,
                    "success": function (json) {
                        cacheLastJson = $.extend(true, {}, json);

                        if (cacheLower != drawStart) {
                            json.data.splice(0, drawStart - cacheLower);
                        }

                        if (requestLength > -1) {
                            json.data.splice(requestLength, json.data.length);
                        }

                        // riempio json che mi serve nel footer
                        settings.json = json;

                        drawCallback(json);
                    }
                });
            } else {
                json = $.extend(true, {}, cacheLastJson);
                json.draw = request.draw; // Update the echo for each response

                if (requestLength > -1) {
                    json.data.splice(0, requestStart - cacheLower);
                    json.data.splice(requestLength, json.data.length);
                }

                drawCallback(json);
            }
        }
    };

// Register an API method that will empty the pipelined data, forcing an Ajax
// fetch on the next draw (i.e. `table.clearPipeline().draw()`)
    $.fn.dataTable.Api.register('clearPipeline()', function () {
        return this.iterator('table', function (settings) {
            settings.clearCache = true;
        });
    });
}

var dataTable = function (options) {

    if (!options) {
        console.error("Specify options!");
        return;
    }

    var dataTable = this;

    // elenco delle righe selezionate ( formatter: dt_multiselect )
    this.selected_ids = [];

    var getToggle = function (option, defaultValue) {
        var ret = (typeof option !== 'undefined') ? option : defaultValue;
        if (ret === "true" || ret === "1")
            ret = true;
        if (ret === "false" || ret === "0")
            ret = false;

        return ret;
    };

    this.idTable = "";
    this.subTableTemplate = "subTableTemplate";
    this.subBodyTemplate = "subBodyTemplate";
    this.table = undefined;

    this.fixedHeader = options.fixedHeader || false;
    this.csv = options.csv || false;
    this.pdf = options.pdf || false;

    this.idTable = options.idTable || "";
    this.columns = options.columns || undefined;
    this.order = options.order || undefined;
    this.searchCols = options.searchCols || undefined;
    this.filterColumns = options.filterColumns || undefined;
    this.lenghtMenu = options.lenghtMenu || [[10, 50, 100, 500, -1], ["10", "50", "100", "500", datatable_lang.all]];
    this.hideLengthAll = getToggle(options.hideLengthAll, false);

    if (this.hideLengthAll) {
        // elimino da lenghtMenu le entry -1
        var lengths = this.lenghtMenu[0];
        var labels = this.lenghtMenu[1];

        $.each(lengths, function (index) {
            if (this == -1) {
                lengths.splice(index, 1);
                labels.splice(index, 1);
            }
        });

        this.lenghtMenu = [lengths, labels];
    }

    this.select = getToggle(options.select, true);
    this.pagination = getToggle(options.pagination, true);
    this.info = getToggle(options.info, true);

    // fixedColumns
    this.fixedColumns = options.fixedColumns || undefined;

    // indica se c'è una subgrid / edit o add inline aperto come row
    this.inlineOpen = false;

    this.$table = $("#" + dataTable.idTable);

    // se qualche colonna è specificata come editable permette che questa lo diventi altrimenti blocca l'edit inline
    // imposto a false solo se esplicitamete settiamo data-dt-editable a false
    this.editable = !(this.$table.attr('data-dt-editable') == 'false');

    // abilita la visualizzazione dell'utltima riga della dataTable contenente i totali
    this.totals = !(typeof this.$table.attr('data-dt-totals') === 'undefined' || this.$table.attr('data-dt-totals') == 'false');

    // pipeline
    this.pipeline = parseInt(this.$table.attr('data-dt-pipeline'));

    // se visualizzare o meno il block ui durante la fase di processing
    this.blockUI = !!(typeof options.blockUI !== 'undefined' && options.blockUI);
    // serve per far visualizzare solo temporaneamente il blockUI
    this.toggleBlockUI = false;

    this.$table.on('processing.dt', function (a, b, processing) {
        if (dataTable.blockUI)
            app.blockUI(processing);

        if (!processing && dataTable.toggleBlockUI) {
            dataTable.blockUI = !dataTable.blockUI;
            dataTable.toggleBlockUI = false;
        }
    });

    // titolo stampato
    this.title = this.$table.attr("data-dt-title");
    this.sessionEnabled = !(this.$table.attr('data-dt-session-enabled') == 'false');

    this.displayStart = parseInt(this.$table.attr("data-dt-start"));
    this.pageLength = parseInt(this.$table.attr("data-dt-length"));

    // deferLoading
    this.initData = this.$table.attr("data-dt-init-data");
    if (this.initData == "false")
        this.initData = false;

    if (!this.title) this.title = "";

    // nome della configurazione
    this.configuration = this.$table.attr("data-dt-configuration");

    // nome della variabile in sessione per i parametri custom della tabella
    this.dt_session = this.$table.attr("data-dt-session") || undefined;

    // torna l'url compresa dei parametri dt e url-parameters
    this.getUrlWithParameters = function (url) {
        if (typeof url != "undefined") {
            if (url.indexOf("?") >= 0)
                url += "&" + dataTable.ajaxDtParameters;
            else
                url += "?" + dataTable.ajaxDtParameters;

            if (typeof dataTable.ajaxUrlParameters != "undefined") {
                return url + "&" + dataTable.ajaxUrlParameters;
            }
        }

        return url;
    };

    // url per server processing e edit|delete
    this.baseUrl = this.$table.attr("data-dt-url");
    this.ajaxDtParameters = "dt=" + this.configuration + ((typeof this.dt_session !== 'undefined') ? "&dt_session=" + this.dt_session : "");
    this.ajaxUrlParameters = this.$table.attr("data-dt-url-parameters");
    this.ajaxUrl = this.getUrlWithParameters(this.baseUrl + "/dt");
    this.ajaxEditUrl = this.getUrlWithParameters(this.baseUrl + "/dt?op=inline");

    // array di oggetti per i custom buttons. Di default viene gestito in automatico solo add
    this.customButtons = [];

    this.inlineButtons = false;
    this.inlineButtonsConfig = undefined;

    this.scrollY = options.scrollY || undefined;
    this.scrollX = options.scrollY || undefined;

    // funzione per customizzare la stampa pdf
    this.printCallback = function (doc) {
        var colCount = [];
        dataTable.$table.find('tbody tr:first-child td').each(function () {
            if ($(this).attr('colspan')) {
                for (var i = 1; i <= $(this).attr('colspan'); i++) {
                    colCount.push('*');
                }
            } else {
                colCount.push('*');
            }
        });
        if (doc.content[1].table) {
            doc.content[1].table.widths = colCount;
        }
    };
    // override funzione stampa
    if (window[this.$table.attr("data-dt-print")] == 'function') this.printCallback = this.$table.attr("data-dt-print");

    // funzione per customizzare l'export
    this.exportCallback = this.$table.attr("data-dt-export");

    // serve per mettere il focus su un elemento una volta completato il redraw
    this.focusAfterDraw = undefined;

    this.nInlineButton = 0;

    this.DrawCallbackSettings = undefined;

    // funzione che crea l'array di colonne per la dataTable
    this.makeColumns = function () {
        var doSort = typeof dataTable.columns == "undefined";
        var doFilter = typeof dataTable.filterColumns == "undefined";

        var generalSort = dataTable.$table.find('thead').attr("data-dt-enable-sort");
        generalSort = !(typeof generalSort != "undefined" && (generalSort == "false" || generalSort == "0"));

        if (doSort) {
            dataTable.columns = [];
            dataTable.order = [];
        }
        if (doFilter) {
            dataTable.searchCols = [];
            dataTable.filterColumns = [];
        }

        var sort = undefined;
        var filter = undefined;
        var columnIndex = undefined;

        dataTable.$table.find("thead").find("tr").find("th").each(function (index) {
            sort = $(this).attr("data-dt-sort");
            if (typeof sort != "undefined" && $.trim(sort) != "" && sort !== 1) {
                sort = $.parseJSON(sort);
            }
            filter = $(this).attr("data-dt-filter");
            if (typeof filter != "undefined")
                filter = $.parseJSON(filter);
            columnIndex = $(this).attr("data-dt-column-index");

            if (typeof $(this).attr("data-dt-inline-button") !== 'undefined') {
                var colBtnVisible = false;
                var subgridParam = $(this).attr("data-dt-subgrid") || undefined;
                var buttons = "";
                if (typeof subgridParam !== 'undefined') {
                    var subPar = JSON.parse(subgridParam);
                    colBtnVisible = true;
                    dataTable.inlineButtons = true;

                    $.each(subPar, function (i, obj) {
                        var dt = (typeof obj.dt != 'undefined' ? obj.dt : '');

                        buttons = buttons + '<button type="button" title="' + ((typeof obj.title != "undefined") ? obj.title : "") + '" data-interaction="subgrid" data-dt="' + dt + '" data-method="' + ((typeof obj.method != "undefined") ? obj.method : "post") + '" data-url="' + obj.url + '" class="btn btn-link btn-warning"><i data-class-close="' + (obj.icon || 'fa fa-minus') + '" data-class-open="' + (obj.icon || 'fa fa-plus') + '" class="' + (obj.icon || 'fa fa-plus') + '"></i></button>';
                        dataTable.nInlineButton++;
                    });
                }
                if ($(this).attr("data-dt-inline-button") == "1") {
                    // colonna bottoni inline
                    colBtnVisible = true;
                    dataTable.inlineButtons = true;
                    dataTable.inlineButtonsConfig = $(this).attr("data-dt-button") || 'edit|delete';
                    var editUrl = $(this).attr("data-dt-edit-url") || dataTable.baseUrl;
                    var deleteUrl = $(this).attr("data-dt-delete-url") || dataTable.baseUrl;

                    editUrl = lib.getAceLink(editUrl);
                    var editInlineUrl = editUrl.replace("#", "/");

                    $.each(dataTable.inlineButtonsConfig.split("|"), function (i, obj) {
                        var method = "get";
                        if (obj.indexOf("[") > -1) {
                            method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                            obj = obj.substr(0, obj.indexOf("["));
                        }
                        dataTable.nInlineButton++;
                        switch (obj) {
                            case "check":
                                buttons = buttons + "<input data-interaction='check' type='checkbox'>";
                                break;
                            case "edit":
                                buttons = buttons + '<a title="Edit" data-dt-inline-button="1" data-interaction="edit" href="' + editUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></a>';
                                break;
                            case "edit_inline":
                                buttons = buttons + '<button type="button" data-method="' + method + '" title="Edit" data-dt-inline-button="1" data-interaction="edit_inline" data-url="' + editInlineUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></button>';
                                break;
                            case "edit_default":
                                buttons = buttons + '<button type="button" title="Edit" data-dt-inline-button="1" data-interaction="edit_default" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></button>';
                                break;
                            case "show":
                                buttons = buttons + '<a title="Show" data-dt-inline-button="1" data-interaction="show" href="' + editUrl + '" class="btn btn-link"><i class="fa fa-eye"></i></a>';
                                break;
                            case "delete":
                                buttons = buttons + '<button type="button" title="Delete" type="button" data-dt-inline-button="1" data-reload="true" data-interaction="delete" data-url="' + deleteUrl + '" class="btn btn-link btn-danger"><i class="fa fa-trash red"></i></button>';
                                break;
                        }
                    });
                }

                dataTable.columns.push({
                    data: null,
                    defaultContent: buttons,
                    className: 'btn-inline-edit',
                    orderable: false,
                    visible: colBtnVisible,
                    createdCell: function (td, cellData) {
                        if (!Array.isArray(cellData))
                            cellData = [cellData];

                        if ($(td).closest('tr').data('add_first')) {
                            $(td).find('[data-interaction=subgrid]').hide();
                        } else {
                            $(td).find('[data-interaction=subgrid]').each(function () {
                                var url = $(this).attr("data-url");
                                if (url.indexOf('%id') !== false) {
                                    url = url.replace('%id', cellData[0]);
                                }
                                $(this).attr("data-url", url);
                            });
                        }

                        $(td).find('[data-interaction=delete]').attr("data-url", dataTable.getUrlWithParameters(deleteUrl + "/" + cellData[0]));
                        $(td).find('[data-interaction=edit]').attr("href", dataTable.getUrlWithParameters(editUrl + "/" + cellData[0] + "/edit"));
                        $(td).find('[data-interaction=show]').attr("href", dataTable.getUrlWithParameters(editUrl + "/" + cellData[0]));
                        $(td).find('[data-interaction=check]').attr("data-id", cellData[0]);
                        $(td).find('[data-interaction=edit_inline]').attr("data-url", dataTable.getUrlWithParameters(editInlineUrl + "/" + cellData[0] + "/edit"));
                    }
                });
                dataTable.searchCols.push(null);
            } else {
                // colonne dati
                var formatterFunction = $(this).attr("data-dt-formatterjs") || undefined;
                var align = $(this).attr("data-dt-align") || undefined;
                var editable = $(this).attr("data-editable") || undefined;
                var tooltip = $(this).attr("data-tooltip") || undefined;
                var tooltipCell = $(this).attr("data-tooltip-cell") || undefined;
                var uid = $(this).attr("data-dt-column-uid") || "";
                var objColumn = {};

                if (doSort) {
                    if (typeof sort != "undefined" && $.trim(sort) != "" && sort != "false" && sort != "0" && generalSort) {
                        objColumn = {"orderable": true};
                        if (typeof sort.order != "undefined" && sort.order != null) {
                            dataTable.order.push([index, sort.order.toLowerCase(), (sort.priority !== 'undefined' ? sort.priority : 1)]);
                        }
                    } else {
                        objColumn = {"orderable": false};
                    }

                    if (typeof align != "undefined")
                        objColumn.sClass = "text-" + align;

                    objColumn.columnIndex = columnIndex;

                    if (formatterFunction) {
                        var formatterFunctionCallback = null;

                        try {
                            // il formatter è parte del set base dei formatter
                            if (typeof(window['formatter']) == 'object')
                                if (typeof(window['formatter'][formatterFunction]) == 'function')
                                    formatterFunctionCallback = window['formatter'][formatterFunction];

                            // il formatter è una funzione custom nella forma 'nomeFunzione' o 'oggetto.nomeFunzione'
                            if (formatterFunctionCallback == null) {
                                if (typeof(window[formatterFunction]) == 'function') {
                                    formatterFunctionCallback = window[formatterFunction];
                                } else if (formatterFunction.indexOf('.') > 0) {
                                    var fnObj = formatterFunction.split('.')[0];
                                    var fnFunction = formatterFunction.split('.')[1];

                                    if (typeof(window[fnObj][fnFunction]) == 'function') {
                                        formatterFunctionCallback = window[fnObj][fnFunction];
                                    }
                                }
                            }

                            if (typeof formatterFunctionCallback == 'function') {
                                objColumn.createdCell = function (td, cell_data, row_data, row_index, col_index) {
                                    dataTable.$table.trigger("dt.formatter.pre." + formatterFunction, td, cell_data, row_data, row_index, col_index);
                                    $(td).html(formatterFunctionCallback(dataTable, td, cell_data, row_data, row_index, col_index));
                                    dataTable.$table.trigger("dt.formatter.after." + formatterFunction, td, cell_data, row_data, row_index, col_index);
                                    dataTable.DTfnCreatedCell(td, cell_data, row_data, row_index, col_index);
                                };
                            } else {
                                objColumn.createdCell = function (td, cell_data, row_data, row_index, col_index) {
                                    dataTable.$table.trigger("dt.formatter.pre." + formatterFunction, td, cell_data, row_data, row_index, col_index);
                                    if (typeof dataTable.$table.data(formatterFunction) == 'function') {
                                        formatterFunctionCallback = dataTable.$table.data(formatterFunction);
                                        $(td).html(formatterFunctionCallback(dataTable, td, cell_data, row_data, row_index, col_index));
                                    }
                                    dataTable.$table.trigger("dt.formatter.after." + formatterFunction, td, cell_data, row_data, row_index, col_index);
                                    dataTable.DTfnCreatedCell(td, cell_data, row_data, row_index, col_index);
                                };
                            }
                        } catch (err) {
                            console.warn("Callback '" + formatterFunction + "' not found (column index: " + index + ")");
                        }
                    }

                    if (editable) {
                        objColumn.editable = $.parseJSON(editable);
                    }

                    if (tooltip) {
                        objColumn.tooltip = $.parseJSON(tooltip);
                    }

                    if (tooltipCell) {
                        objColumn.tooltipCell = tooltipCell;
                    }

                    objColumn.uid = uid;
                    dataTable.columns.push(objColumn);
                }

                if (typeof filter.init_value != "undefined" && filter.init_value != null) {
                    dataTable.searchCols.push({
                        search: "" + filter.init_value.id
                    });
                } else {
                    dataTable.searchCols.push(null);
                }

                if (doFilter) {
                    if (typeof filter != "undefined" && filter != "false" && filter != "0") {
                        dataTable.filterColumns[index] = {
                            index: index,
                            columnIndex: columnIndex,
                            filter: filter
                        };
                    }
                }
            }
        });

        dataTable.order.sort(function (a, b) {
            return a[2] - b[2];
        });
    };

    this.makeFilters = function () {
        var filter = dataTable.$table.find('thead').attr("data-dt-enable-filter");
        if (typeof filter != "undefined" && filter != "false" && filter != "0") {
            /**
             * Ho abilitato i filtri.
             * Creo una nuova tr dove ci saranno i filtri
             */
            var n = dataTable.$table.find("thead").find("tr").find("th").length;
            var $tr = $('<tr data-dt-filter="1"></tr>');
            for (var i = 0; i < n; i++) {
                var $th = $('<th data-filter="' + i + '">' + +'</th>');
                $tr.append($th);
            }
            var content = dataTable.$table.find("thead").html();
            dataTable.$table.find("thead").html($tr);
            dataTable.$table.find("thead").append(content);
        }
    };

    this.makeSorting = function () {
        // date
        $.fn.dataTable.moment('DD/MM/YYYY');

        // numeric
        $.fn.dataTable.ext.order['numeric'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(",").join("");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };

        // numeric comma
        $.fn.dataTable.ext.order['numeric-comma'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(".").join("");
                value = value.split(",").join(".");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };
    };

    this.DTfnBeforeDrawCallback = function (settings) {
    };

    this.DTfnDrawCallback = function (settings, callback) {
        dataTable.DrawCallbackSettings = settings;
        dataTable.DTfnBeforeDrawCallback(settings);
        dataTable.bindEvents();
        if (dataTable.focusAfterDraw !== undefined) {
            $(dataTable.table.cell(dataTable.focusAfterDraw).node()).find('.data-dt-focus').focus();
            dataTable.focusAfterDraw = undefined;
        }

        if (typeof callback == 'function')
            callback();

        dataTable.DTfnAfterDrawCallback(settings);
    };
    this.DTfnAfterDrawCallback = function (settings) {
    };

    /**
     * Funzione da overridare
     */
    this.DTbeforeInitComplete = function () {
    };
    /**
     * Funzione da overridare
     */
    this.DTafterInitComplete = function () {
    };

    this.filters = [];

    this.getFilters = function () {
        return dataTable.filters;
    };

    this.DTinitComplete = function (settings, json) {
        dataTable.DTbeforeInitComplete();

        this.api().columns().every(function (i) {
            var filterColumn = dataTable.filterColumns[i];
            if (filterColumn) {
                var column = this;
                var $search = undefined;
                var $from = undefined;
                var $to = undefined;
                var $appendTo = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");
                var jsonVal = undefined;
                var columnIndex = filterColumn.columnIndex;

                var filter = filterColumn.filter;
                var type = filter.type;
                if (typeof type === 'undefined' || type == null || type.trim() == '')
                    type = 'select';

                switch (type) {
                    case "time":
                    case "date":
                    case "datetime":
                    case "timesingle":
                    case "datesingle":
                    case "datetimesingle":
                        var c = 'datepicker';
                        var place = 'Da';
                        if (filter.type == 'datetime' || filter.type == 'datetimesingle') c = 'datetimepicker';
                        if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                        if (filter.type == 'time' || filter.type == 'timesingle') c = "timepicker";
                        $from = $('<input type="text" class="' + c + ' dtfrom" placeholder="' + place + '">');
                        // var $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                        // var $fromWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                        // $fromWrapper.append($calendar);
                        // $fromWrapper.append($from);
                        $from.appendTo($appendTo.empty());
                        var timepicker = false;
                        var datepicker = true;
                        var format = 'd/m/Y';
                        var step = undefined;
                        if (filter.type == 'datetime') {
                            format = 'd/m/Y H:i';
                            timepicker = true;
                            step = 30;
                        }
                        if (filter.type == 'time') {
                            format = 'H:i';
                            timepicker = true;
                            datepicker = false;
                            step = 30;
                        }
                        $from.datetimepicker({
                            dayOfWeekStart: 1,
                            // mask: true,
                            format: format,
                            step: step,
                            closeOnDateSelect: true,
                            datepicker: datepicker,
                            timepicker: timepicker,
                            scrollMonth: false,
                            scrollInput: false,
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            if (filter.type == 'datesingle' || filter.type == 'datetimesingle') {
                                column.search(from ? from : '', true, false).draw();
                            } else {
                                var to = $(this).closest('th').find('.dtto').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                if ($.trim(from) == "" && $.trim(to) == "") {
                                    column.search("", true, false).draw();
                                    dataTable.filters[columnIndex] = "";
                                } else {
                                    column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                                    dataTable.filters[columnIndex] = JSON.stringify({from: from, to: to});
                                }
                            }
                        });

                        if (
                            typeof filter.init_value != "undefined" &&
                            typeof filter.init_value.id != "undefined" &&
                            filter.init_value.id != null &&
                            $.trim(filter.init_value.id) != ""
                        ) {
                            if (filter.type === "datesingle" || filter.type === "datetimesingle" || filter.type === "timesingle") {
                                $from.val(filter.init_value.id);
                            } else {
                                jsonVal = JSON.parse(filter.init_value.id);
                                $from.val(jsonVal.from);
                            }
                        }

                        if (filter.type == "date" || filter.type == "datetime" || filter.type == "time") {
                            place = 'A';
                            if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                            $to = $('<input type="text" class="' + c + ' dtto" placeholder="' + place + '">');
                            // $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                            // var $toWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                            // $toWrapper.append($calendar);
                            // $toWrapper.append($to);
                            $to.appendTo($appendTo);
                            $to.datetimepicker({
                                dayOfWeekStart: 1,
                                // mask: true,
                                format: format,
                                closeOnDateSelect: true,
                                datepicker: datepicker,
                                timepicker: timepicker,
                                scrollInput: false
                            });
                            $to.on('change', function () {
                                var to = $(this).val();
                                var from = $(this).closest('th').find('.dtfrom').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                if ($.trim(from) == "" && $.trim(to) == "") {
                                    column.search("", true, false).draw();
                                    dataTable.filters[columnIndex] = "";
                                } else {
                                    column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                                    dataTable.filters[columnIndex] = JSON.stringify({from: from, to: to});
                                }
                            });

                            if (
                                typeof filter.init_value != "undefined" &&
                                typeof filter.init_value.id != "undefined" &&
                                filter.init_value.id != null &&
                                $.trim(filter.init_value.id) != ""
                            ) {
                                jsonVal = JSON.parse(filter.init_value.id);
                                $to.val(jsonVal.to);
                            }
                        }
                        break;
                    case "number":
                        $from = $('<input placeholder="Da" type="text" class="dtfrom" style="width: 100%;">'); // TODO gestione lingue
                        $from.appendTo($appendTo.empty());
                        $from.on('click', function (e) {
                            e.stopPropagation();
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            var to = $(this).closest('th').find('.dtto').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            if ($.trim(from) == "" && $.trim(to) == "") {
                                column.search("", true, false).draw();
                                dataTable.filters[columnIndex] = "";
                            } else {
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                                dataTable.filters[columnIndex] = JSON.stringify({from: from, to: to});
                            }
                        });

                        if (
                            typeof filter.init_value != "undefined" &&
                            typeof filter.init_value.id != "undefined" &&
                            filter.init_value.id != null &&
                            $.trim(filter.init_value.id) != ""
                        ) {
                            jsonVal = JSON.parse(filter.init_value.id);
                        }

                        if (typeof jsonVal != "undefined" && typeof jsonVal.from != "undefined") {
                            $from.val(jsonVal.from);
                        }


                        $to = $('<input placeholder="A" type="text" class="dtto" style="width: 100%;">'); // TODO gestione lingue
                        $to.appendTo($appendTo);
                        $to.on('change', function () {
                            var to = $(this).val();
                            var from = $(this).closest('th').find('.dtfrom').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            if ($.trim(from) == "" && $.trim(to) == "")
                                column.search("", true, false).draw();
                            else
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                        });

                        if (typeof jsonVal != "undefined" && typeof jsonVal.to != "undefined") {
                            $to.val(jsonVal.to);
                        }
                        break;
                    case 'multi':
                    case 'select':
                        var node = '<select class="select2" style="width: 100%;" data-placeholder=" "><option value=""></option></select>';
                        if (type == 'multi') {
                            node = '<select class="select2" multiple style="width: 100%;" data-placeholder=" "></select>';
                        }
                        $search = $(node)
                            .appendTo($appendTo.empty())
                            .on('change', function () {
                                var val = $(this).val();

                                if (type == 'multi') {
                                    val = [];
                                    $.each($(this).select2('data'), function () {
                                        val.push($.trim(this.id));
                                    });
                                    val = val.join("||");
                                }
                                dataTable.filters[columnIndex] = val;
                                column.search(val ? val : '', true, false).draw();
                            });

                        if (filter.init_value) {
                            if (type == 'multi') {
                                if ($.trim(filter.init_value.id) != "") {
                                    var ids = filter.init_value.id.split("||");
                                    var texts = filter.init_value.text.split("||");
                                    var filters = [];
                                    for (var i = 0; i < ids.length; i++) {
                                        $search.append('<option selected value="' + ids[i].replace('"', '&quot;') + '">' + texts[i] + '</option>');
                                        filters.push(ids[i].replace('"', '&quot;'));
                                    }
                                    dataTable.filters[columnIndex] = filters.join("||");
                                }
                            } else {
                                var filter_init_value_id = filter.init_value.id + '';
                                $search.append('<option selected value="' + filter_init_value_id.replace('"', '&quot;') + '">' + filter.init_value.text + '</option>');
                                dataTable.filters[columnIndex] = filter_init_value_id.replace('"', '&quot;');
                            }
                        }

                        var url = filter.url || dataTable.ajaxUrl;

                        if (typeof columnIndex !== 'undefined') {
                            // creo l'url che verrà contattata dalla select2 per recuperare i dati
                            url += "&op=autocomplete&dt_col_index=" + columnIndex;

                            // template di visualizzazione dei risultati della select2
                            function templateResult(data) {
                                return data.text;
                            }

                            // template di visualizzazione del risultato selezionato della select2
                            function templateSelection(data) {
                                if (typeof data.label == "undefined")
                                    data.label = data.text;
                                return data.label;
                            }

                            var minimumInputLength = 0;
                            if (typeof filter.preload != "undefined" && filter.preload == false)
                                minimumInputLength = 1;

                            var minimumResultsForSearch = 8;
                            if (typeof filter.search != "undefined" && filter.search == true)
                                minimumResultsForSearch = undefined;

                            $search.select2({
                                ajax: {
                                    url: url,
                                    dataType: 'json',
                                    delay: 100,
                                    type: 'POST',
                                    data: function (params) {
                                        return {
                                            search: params.term, // search term
                                            page: params.page,
                                            _filter: dataTable.getFilters()
                                        };
                                    },
                                    processResults: function (data, params) {
                                        // parse the results into the format expected by Select2
                                        // since we are using custom formatting functions we do not need to
                                        // alter the remote JSON data, except to indicate that infinite
                                        // scrolling can be used
                                        params.page = params.page || 1;

                                        return {
                                            results: data.items
                                        };
                                    },
                                    cache: true
                                },
                                escapeMarkup: function (markup) {
                                    return markup;
                                },
                                minimumInputLength: minimumInputLength,
                                minimumResultsForSearch: minimumResultsForSearch,
                                allowClear: type === 'multi' ? false : typeof filter.nullable !== 'undefined' ? filter.nullable : true,
                                cache: true,
                                templateResult: templateResult,
                                templateSelection: templateSelection
                            }).on("select2:unselecting", function (e) {
                                $(this).data('state', 'unselected');
                            }).on("select2:open", function (e) {
                                if ($(this).data('state') === 'unselected') {
                                    $(this).removeData('state');

                                    // dirt! purtroppo il preventDefault non fa il suo lavoro!
                                    var self = $(this);
                                    setTimeout(function () {
                                        self.select2('close');
                                    }, 1);
                                }
                            });
                        }
                        break;
                    case 'text':
                        $search = $('<input style="width: 100%;">')
                            .appendTo($appendTo.empty())
                            .on('change', function () {
                                var val = $(this).val();
                                dataTable.filters[columnIndex] = val;
                                column.search(val ? val : '', true, false).draw();
                            });

                        if (filter.init_value) {
                            var filter_init_value_id = filter.init_value.id + '';
                            $search.val(filter_init_value_id.replace('"', '&quot;'));
                        }
                        break;
                }
            }
        });

        // faccio diventare select2 il selettore del numero di elementi da visualizzare
        $('select[name="' + dataTable.idTable + '_length"]').select2();

        dataTable.DTafterInitComplete();
    };

    this.DTfnCreatedRow = function (nRow, aData) {
        $(nRow).attr('data-id', aData[0]);
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");


        if (typeof aData[0].indexOf == 'function' && aData[0].indexOf("#group-") != -1) {
            var fontInc = aData[0].replace("#", '').replace("group-", '').replace("#", '');
            fontInc = (100 + fontInc * 5);
            if ($.isNumeric(fontInc)) {
                $(nRow).css("font-size", fontInc + "%");
            }
            $(nRow).css("background-color", "#eee");
            $(nRow).css("font-weight", "bold");
        }

        if (typeof dataTable.fixedColumns == "undefined") {
            // per ogni cella forzo la max-width della th relativa
            var index = 0;
            $(nRow).find("td").not('.btn-inline-edit').each(function () {

                if (typeof dataTable.columnDefs[index] != "undefined" && typeof dataTable.columnDefs[index].width != "undefined") {
                    $(this).css("max-width", dataTable.columnDefs[index].width);
                }

                index++;
            });
        }

        dataTable.afterDTfnCreatedRow(nRow, aData);
    };

    this.afterDTfnCreatedRow = function (nRow, aData) {
    };

    this.afterEditInline = function (dt, $row, data) {
        var c = new crud({
            form: $row.find("form").attr("id")
        });

        c.successSave = function ($form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                app.success("", "Salvato!");
                dataTable.table.clearPipeline().draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if (typeof callback !== 'undefined') {
                var fnz = app.eval(callback);
                fnz(data);
            }
            if (!hideLoader)
                app.block(0);
        };
        c.successDelete = function ($form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                dataTable.table.draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if (typeof callback !== 'undefined') {
                window[callback](data);
            }
            if (!hideLoader)
                app.block(0);
        };

        $row.find('.row').css('background-color', 'transparent');
        $row.find('.form-group').css('margin-bottom', '5px').css('display', 'block');
        $row.find('input[type=text]').css('margin', 'auto');
        $row.find('.form-control').css('width', '100%');

        $row.find('[data-interaction=cancel]').unbind('click').bind('click', function (e) {
            e.preventDefault();

            $row.hide();

            dataTable.inlineOpen = false;
        });
    };

    this.instance = {};

    this.bindEvents = function () {
        // si assicura che le colonne con i filtri siano allineate con le colonne visualizzate
        // questo perchè con responsive: true alcune colonne vengono nascoste così vado a nascondere anche i filtri correlati
        var onResize = function () {
            if (dataTable.table) {
                dataTable.table.columns().every(function (i) {
                    var $th = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");
                    if (!$(this.header()).is(":visible")) {
                        $th.hide();
                    } else {
                        $th.show();
                    }
                });
            }
        };
        var resize = undefined;
        $(window).resize(function () {
            clearTimeout(resize);
            resize = setTimeout(function () {
                onResize();
            }, 250);
        });

        setTimeout(function () {
            onResize();
        }, 250);

        dataTable.$table.on("remove", function () {
            // se era bindato un fixedheader, viene rimosso per evitare sovrapposizioni di fixedheader tra tabelle su pagine differenti
            dataTable.table.fixedHeader.disable();

            // codice per evitare memory leak
            $.fn.dataTable.ext.order['numeric'] = undefined;
            $.fn.dataTable.ext.order['numeric-comma'] = undefined;
            $.fn.dataTable.ext.errMode = undefined;
            $(window).unbind('resize');
            dataTable.$table.colResizable({disable: true});
            // dataTable.$table.dataTableRemover();

            dataTable.table.destroy();
        });

        dataTable.$table.find('[data-interaction="edit_default"]').unbind("click").bind("click", function () {
            var editing = parseInt($(this).attr('data-editing'));
            var $tr = $(this).closest("tr");
            var data = [];
            if ($tr.data('add_first') && typeof $tr.data('data') !== 'undefined') {
                // esempio nell'add_first dataTable non sa che riga sia quindi leggo i dati da un attributo
                data = $tr.data('data');
            } else {
                data = dataTable.table.row($tr).data();
            }

            if (editing) {
                if ($tr.data('add_first')) {
                    if ($tr.data('add_client')) {
                        // devo leggere i dati inseriti e creare un elemento con questi dati
                        var editable = $tr.data('editable');

                        // block-ui e spinner
                        app.block(editable.block);
                        if (editable.spinner)
                            editable.loader();

                        $.post(dataTable.ajaxUrl, {op: 'store', _data: $tr.data('data_to_send')})
                            .done(function (data) {
                                if (data.response) {
                                    $tr.remove();
                                    dataTable.redrawPage();
                                    dataTable.inlineOpen = false;
                                } else {
                                    app.warning("", data.message);
                                }

                                app.block(0);
                                editable.$spinner.hide();

                                dataTable.$table.trigger('dataTable.add_client.done', data);
                            })
                            .fail(function (data) {
                                app.block(0);
                                editable.$spinner.hide();
                                var error = app.parseAjaxError(data);
                                if ($.trim(error) == "")
                                    error = "Errore AJAX!";

                                app.error("", error);
                            });
                    } else {
                        // devo semplicemente fare un redraw
                        dataTable.redrawPage();

                        $(this).attr("data-editing", 0);
                        $(this).html('<i class="fa fa-pencil"></i>');
                    }
                } else {
                    // aggiorno la riga
                    dataTable.table.row($tr).data(data);
                    $tr.find("td").each(function (index) {
                        var colIndex = dataTable.inlineButtons ? index : index + 1;

                        if (typeof dataTable.columns[index].createdCell !== 'undefined' && typeof data[colIndex] !== 'undefined') {
                            dataTable.columns[index].createdCell($(this), data[colIndex], data, dataTable.table.row($tr).index(), colIndex);
                        }
                    });


                }
            } else {
                if (dataTable.editable)
                    return;

                $(this).attr("data-editing", 1);
                $(this).html('<i class="fa fa-check green"></i>');

                dataTable.editable = true;

                $tr.find("td").each(function (index) {
                    if ($(this).data('end'))
                        return;

                    var colIndex = dataTable.inlineButtons ? index : index + 1;

                    if (typeof dataTable.columns[index].createdCell !== 'undefined' && typeof data[colIndex] !== 'undefined') {
                        dataTable.columns[index].createdCell($(this), data[colIndex], data, dataTable.table.row($tr).index(), colIndex);
                    } else {
                        dataTable.DTfnCreatedCell($(this), data[index], data, dataTable.table.row($tr).index(), colIndex);
                    }
                });

                dataTable.editable = false;
            }

            dataTable.DTfnCreatedRow($tr, data);
            dataTable.DTfnDrawCallback(dataTable.DrawCallbackSettings);
        });

        dataTable.$table.find('tr').on('dblclick', function () {
            var $edit_default = $(this).find('[data-interaction=edit_default]');
            if (!parseInt($edit_default.attr('data-editing'))) {
                $edit_default.trigger('click');
            }
        });

        dataTable.$table.find('[data-interaction="edit_inline"]').unbind("click").bind("click", function () {
            var url = $(this).attr("data-url");
            var method = $(this).attr("data-method");
            var row = dataTable.table.row($(this).closest('tr'));

            if (row.child.isShown()) {
                row.child.hide();
                dataTable.inlineOpen = false;
            } else {
                if (dataTable.inlineOpen == false) {
                    $[method](url)
                        .done(function (data) {
                            var child = row.child(data);
                            child.show();
                            app.runBind();
                            dataTable.inlineOpen = true;
                            dataTable.afterEditInline(dataTable, $(row.child()[0]), data);
                        })
                        .fail(function () {
                            app.error("", "Errore server!");
                        });
                }
            }
        });

        dataTable.$table.find('[data-interaction="subgrid"]').unbind("click").bind("click", function () {
            var $tr = $(this).closest('tr');

            if ($tr.data('add_client'))
                return;

            var objI = $(this).find('i');
            if (objI.hasClass(objI.attr('data-class-open'))) {
                objI.removeClass(objI.attr('data-class-open'));
                objI.addClass(objI.attr('data-class-close'));
            } else {
                objI.removeClass(objI.attr('data-class-close'));
                objI.addClass(objI.attr('data-class-open'));
            }


            var id = $(this).attr("data-id") || $(this).closest('tr').attr('data-id');
            var url = $(this).attr("data-url");
            var dt = $(this).closest('table').attr('data-dt-configuration');
            var method = $(this).attr("data-method");
            var callbackName = $(this).attr("data-callback");
            var row = dataTable.table.row($tr);
            var dt_subgrid = $.trim($(this).attr("data-dt")) != '' ? $(this).attr("data-dt") + "_" : 'default_';

            // verifico se la callback esiste
            var callback = false;
            if (typeof callbackName !== 'undefined') {
                if (jQuery.isFunction(callbackName))
                    callback = true;
                if (jQuery.isFunction(eval(callbackName)))
                    callback = true;
            }

            if (row.child.isShown()) {
                row.child.hide();
                $tr.removeClass('shown');
            } else {
                app.block(1);

                $[method](url, {id_subgrid: id, dt: dt_subgrid + id, _subgrid: 1})
                    .success(function (data) {
                        if (data) {
                            if (callback) {
                                eval(callbackName)(row, data);
                            } else {
                                row.child(data).show();
                                $tr.addClass('shown');
                                if (row.child().length > 0) {
                                    var newTr = row.child()[0];
                                    $(newTr).children('td').css('border', '2px solid #08C');
                                }
                            }
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                    });
            }
        });

        if (dataTable.selected_ids.length) {
            // se sono elementi che devono essere selezionati paginando, li seleziono
            $.each(dataTable.selected_ids, function (index, obj) {
                dataTable.$table.find("[data-interaction='check'][data-id='" + obj.replace("\"", "\\\"") + "']").prop("checked", true);
            });
        }
        dataTable.$table.find('[data-interaction="check"]').unbind("change").bind("change", function () {
            var id_row = $(this).attr("data-id");
            if ($(this).is(":checked")) {
                dataTable.selected_ids.push(id_row);
            } else {
                var pos = dataTable.selected_ids.indexOf(id_row)
                if (pos != -1) {
                    dataTable.selected_ids.splice(pos, 1);
                }
            }
        });

        dataTable.$table.find('[data-dt-inline-button="1"][data-interaction=delete]').unbind('click').bind('click', function () {
            var conferma = datatable_lang.askDelete || 'Eliminare la riga selezionata?';

            var id = $(this).closest("tr").attr("data-id");

            // formattazione stringa richiesta parsando le {}
            $.each(dataTable.columns, function (index, obj) {
                if (typeof obj.field !== 'undefined') {
                    if (conferma.indexOf("{" + obj.field + "}") != -1) {

                        var valInCell = dataTable.table.cell("[data-id=" + id + "] td:eq(" + index + ")").data();

                        // recupero il campo nella colonna alla riga attuale
                        conferma = conferma.replace("{" + obj.field + "}", valInCell);
                    }
                }
            });

            if (confirm(conferma)) {
                if ($(this).closest('tr').data('add_client')) {
                    $(this).closest('tr').remove();
                    dataTable.inlineOpen = false;
                } else {
                    dataTable.deleteRow(this);
                }
            }
        });

        this.afterBindEvents();
    };

    this.preDeleteRow = function () {
        return true;
    };

    this.deleteRow = function (row) {
        var url = $(row).attr('data-url');
        var id = $(row).closest("tr").attr("data-id");

        if (!dataTable.preDeleteRow(row))
            return;

        app.block(1);
        $.delete(url)
            .success(function (data) {
                if (data.response) {
                    dataTable.redrawPage();

                    // se è tra gli elementi selezionati lo rimuovo
                    if (dataTable.selected_ids.indexOf(id) >= 0)
                        dataTable.selected_ids.splice(dataTable.selected_ids.indexOf(id), 1);

                    dataTable.afterDeleteRow(row);
                } else {
                    app.warning("", data.message);
                }
                app.block(0);
            })
            .error(function () {
                app.block(0);
                app.error('', 'Delete error!');
            });
    };

    this.afterDeleteRow = function (row) {
    };

    this.getSelectedRows = function () {
        return dataTable.selected_ids;
    };

    this.getHighlightedRows = function (allData) {
        allData = allData || false;
        var data = dataTable.table.rows('.selected').data();
        var ret = [];
        for (var i = 0; i < data.length; i++) {
            if (!allData)
                ret.push(dataTable.table.rows('.selected').data()[i][0]);
            else
                ret.push(dataTable.table.rows('.selected').data()[i]);
        }
        return ret;
    };

    this.afterBindEvents = function () {
    };

    this.makeCustomButtons = function () {
        var buttons = dataTable.$table.attr("data-dt-custom-button");

        if (typeof datatable_lang.customButton === 'undefined')
            datatable_lang.customButton = {};

        if (typeof datatable_lang.customButton.tooltip === 'undefined')
            datatable_lang.customButton.tooltip = {};

        if (buttons && buttons.trim() != "") {
            try {
                buttons = JSON.parse(buttons);
            } catch (e) {
                buttons = buttons.split("|");
            }
            $.each(buttons, function (index, obj) {
                var label, icon, cls, tooltip;

                if (typeof obj == 'object') {
                    if (typeof obj.label !== 'undefined' && $.trim(obj.label) !== "")
                        label = obj.label;

                    if (typeof obj.icon !== 'undefined' && $.trim(obj.icon) !== "")
                        icon = obj.icon;

                    if (typeof obj.class !== 'undefined' && $.trim(obj.class) !== "")
                        cls = obj.class;

                    if (typeof obj.tooltip !== 'undefined' && $.trim(obj.tooltip !== ''))
                        tooltip = obj.tooltip;

                    obj = obj.action;
                }

                var method = "get";
                if (obj.indexOf("[") > -1) {
                    method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                    obj = obj.substr(0, obj.indexOf("["));
                }
                switch (obj) {
                    case 'add':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiungi';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-plus';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    app.href(dataTable.getUrlWithParameters(dataTable.baseUrl + "/create"));
                                }
                            };
                        }
                        break;
                    case 'add_inline':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiungi';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-plus';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    if (dataTable.editable) {
                                        var url = dataTable.getUrlWithParameters(dataTable.baseUrl + "/create");

                                        // inserisco al posto del messaggio che dice che non ci sono dati
                                        var $row = $(dataTable.table.table().body());

                                        if (dataTable.inlineOpen == false) {
                                            $[method](url, {dt_inline: 1})
                                                .done(function (data) {
                                                    var $tr = $('<tr></tr>');
                                                    var $td = $('<td colspan="' + dataTable.columns.length + '"></td>');
                                                    $td.html(data);
                                                    $tr.html($td);
                                                    $row.prepend($tr);
                                                    app.runBind();
                                                    dataTable.inlineOpen = true;
                                                    dataTable.afterEditInline(dataTable, $tr, data);
                                                })
                                                .fail(function () {
                                                    app.error("", "Errore server!");
                                                });
                                        }
                                    } else {
                                        app.warning("", "Devi prima abilitare le modifiche!");
                                    }
                                }
                            };
                        }
                        break;
                    case 'add_default':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiungi';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-plus';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right _add_default " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    app.block(1);
                                    $.post(dataTable.getUrlWithParameters(dataTable.baseUrl))
                                        .done(function (data) {
                                            if (data.response) {
                                                if (!(typeof dataTable.inlineButtonsConfig !== 'undefined' && dataTable.inlineButtonsConfig.indexOf('edit_default') >= 0)
                                                    && typeof dataTable.customButtons['lock'] !== 'undefined' && !dataTable.editable) {
                                                    // trigger del tasto lock
                                                    dataTable.customButtons['lock'].action(e, dt, $(node).parent().find(".btn-lock"), config);
                                                }

                                                // redraw
                                                dataTable.redrawPage();
                                            } else {
                                                app.warning("", data.message);
                                            }
                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'add_first':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiungi';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-plus';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right _add_first " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    var url = dataTable.getUrlWithParameters(dataTable.baseUrl);

                                    // inserisco al posto del messaggio che dice che non ci sono dati
                                    var $row = $(dataTable.table.table().body());

                                    if (dataTable.inlineOpen == false) {
                                        var params = dataTable.table.ajax.params();
                                        if (typeof params === 'undefined')
                                            params = {};
                                        params._last = 1;
                                        $.post(url, params)
                                            .done(function (data) {
                                                var last = data.last;
                                                var $tr = $('<tr style="background-color: lightblue;"></tr>');
                                                $tr.data('data', last);
                                                $tr.data('add_first', true);
                                                for (var i = 0; i < last.length; i++) {
                                                    var val = last[i];
                                                    if (i == 0 && dataTable.inlineButtons) {
                                                        val = dataTable.columns[0].defaultContent;
                                                    }
                                                    if (val == null)
                                                        val = "";
                                                    var $td = $('<td class="' + dataTable.columns[i].sClass + '">' + val + '</td>');
                                                    $tr.append($td);
                                                }
                                                $row.prepend($tr);
                                                dataTable.newRow($tr, last, true);
                                            })
                                            .fail(function () {
                                                app.error("", "Errore server!");
                                            });
                                    }
                                }
                            };
                        }
                        break;
                    case 'add_client':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiungi';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-plus';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right _add_client " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    var url = dataTable.getUrlWithParameters(dataTable.baseUrl);

                                    // inserisco al posto del messaggio che dice che non ci sono dati
                                    var $row = $(dataTable.table.table().body());

                                    if (dataTable.inlineOpen == false) {
                                        var $tr = $('<tr data-add-client="1" style="background-color: lightblue;"></tr>');
                                        $tr.data('add_first', true);
                                        $tr.data('add_client', true);
                                        $tr.data('data_to_send', []);

                                        var data = [];
                                        var data_to_send = {};
                                        for (var i = 0; i < dataTable.columns.length; i++) {
                                            var val = "";

                                            if (typeof dataTable.columns[i].editable !== 'undefined' && typeof dataTable.columns[i].editable.default !== 'undefined') {
                                                var def = dataTable.columns[i].editable.default;
                                                if (typeof def == 'object' && typeof def.id !== 'undefined' && (typeof def.text !== 'undefined' || typeof def.label !== 'undefined')) {
                                                    val = typeof def.label !== 'undefined' ? def.label : def.text;
                                                    if (val == null)
                                                        val = "";
                                                    data_to_send[dataTable.columns[i].columnIndex] = def.id;
                                                } else {
                                                    val = def == null ? "" : def;
                                                    data_to_send[dataTable.columns[i].columnIndex] = val;
                                                }
                                            }

                                            if (typeof dataTable.columns[i].defaultContent !== 'undefined')
                                                val = dataTable.columns[i].defaultContent;

                                            $tr.append('<td class="' + dataTable.columns[i].sClass + '">' + val + '</td>');
                                            data.push(val);
                                        }

                                        if (method == 'end') {
                                            $tr.find('[data-interaction=edit_default]').hide();

                                            var $check = $('<button type="button" class="btn btn-link"><i class="fa fa-check green"></i></button>');

                                            $check.on('click', function () {
                                                $(this).closest('tr').find('[data-interaction=edit_default]').trigger('click');
                                            });

                                            var $td = $('<td class="text-center" style="width: 34px;"></td>');
                                            $td.data('end', true);
                                            $td.html($check);

                                            $tr.append($td);
                                        }

                                        $tr.data('data', data);
                                        $tr.data('data_to_send', data_to_send);
                                        $row.prepend($tr);
                                        dataTable.newRow($tr, data, true);
                                        dataTable.inlineOpen = true;
                                    }
                                }
                            };
                        }
                        break;
                    case 'clear':
                    case 'forget':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Reset filtri';

                            if (typeof cls === 'undefined')
                                cls = 'btn-warning';

                            if (typeof icon === 'undefined')
                                icon = 'fa-eraser';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    app.block(1);
                                    $.post(dataTable.ajaxUrl + "&op=forget")
                                        .done(function (data) {
                                            if (data.response) {
                                                // non eseguo solo il draw per evitare di risettare filtri e di esser sicuro di riscrivere la sessione
                                                preference.set(dataTable.idTable + ".displayStart", null);
                                                preference.set(dataTable.idTable + ".pageLength", null);
                                                app.reload();
                                            }

                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'refresh':
                    case 'redraw':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Aggiorna';

                            if (typeof cls === 'undefined')
                                cls = 'btn-success';

                            if (typeof icon === 'undefined')
                                icon = 'fa-refresh';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    dataTable.redrawPage();
                                }
                            };
                        }
                        break;
                    case 'delete':
                    case 'delete_all':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Elimina';

                            if (typeof cls === 'undefined')
                                cls = 'btn-danger';

                            if (typeof icon === 'undefined')
                                icon = 'fa-trash';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    var ids = dataTable.getSelectedRows();
                                    if (ids.length == 0) {
                                        app.warning("", "Selezionare almeno un elemento da eliminare");
                                        return;
                                    }
                                    if (!confirm("Eliminare i (" + ids.length + ") elementi selezionati?")) {
                                        return;
                                    }
                                    app.block(1);
                                    $.post(dataTable.ajaxUrl + "&op=delete", {ids: ids})
                                        .done(function (data) {
                                            if (data.response) {
                                                app.success("", "Elementi eliminati");
                                                dataTable.redrawPage();
                                                dataTable.selected_ids = dataTable.selected_ids.filter(function (value) {
                                                    return ids.indexOf(value) < 0;
                                                });
                                                dataTable.afterDeleteRow();
                                            } else {
                                                app.warning("", data.message);
                                            }
                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'clone':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Duplica';

                            if (typeof cls === 'undefined')
                                cls = 'btn-primary';

                            if (typeof icon === 'undefined')
                                icon = 'fa-clone';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> ' + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    if (dataTable.table.rows('.selected').data().length > 0) {
                                        if (dataTable.table.rows('.selected').data()[0].length > 0) {
                                            var id = dataTable.table.rows('.selected').data()[0][0];

                                            app.block(1);
                                            $.post(dataTable.ajaxUrl, {op: 'clone', _id: id})
                                                .done(function (data) {
                                                    if (data.response) {
                                                        app.success("", "Elemento duplicato");
                                                        dataTable.redrawPage();
                                                    } else {
                                                        app.warning("", data.message);
                                                    }
                                                    app.block(0);
                                                })
                                                .fail(function () {
                                                    app.block(0);
                                                    app.error("", "Errore AJAX!");
                                                });
                                        }
                                    } else {
                                        app.warning("", "Seleziona una riga!");
                                    }
                                }
                            };
                        }
                        break;
                    case 'csv':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'CSV';

                            if (typeof cls === 'undefined')
                                cls = 'btn-info';

                            if (typeof icon === 'undefined')
                                icon = 'fa-file-excel-o';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: "<i class='fa " + icon + "'></i> " + label,
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    var url = dataTable.ajaxUrl + "&op=export&type=csv";

                                    var params = dataTable.table.ajax.params();

                                    if (typeof params === 'undefined')
                                        params = {};

                                    // se viene richiesto il csv e ci sono righe selezionate, viene impostato il parametro selected_ids
                                    if (typeof dataTable != 'undefined' && dataTable.selected_ids != 'undefined' && dataTable.selected_ids.length > 0) {
                                        params['selected_ids'] = dataTable.selected_ids;
                                    }

                                    params.start = 0;
                                    params.length = -1;

                                    app.block(1);
                                    $.post(url, params)
                                        .done(function (data) {
                                            app.block(0);
                                            if (data.response) {
                                                window.location.href = data.message;
                                            } else {
                                                app.warning("", data.message);
                                            }
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore esportazione CSV!");
                                        });
                                }
                            }
                        }
                        break;
                    case 'lock':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = "";

                            if (typeof cls === 'undefined')
                                cls = 'btn-' + ((dataTable.editable) ? "danger" : "warning");

                            if (typeof icon === 'undefined')
                                icon = 'fa-' + ((dataTable.editable) ? "unlock" : "lock");

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: "<i class='fa " + icon + "'></i>" + label,
                                className: "btn-lock btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, dt, node, config) {
                                    if (dataTable.editable) {
                                        // lock
                                        $(node)
                                            .removeClass('btn-danger')
                                            .addClass('btn-warning')
                                            .find('i')
                                            .removeClass('fa-unlock')
                                            .addClass('fa-lock');
                                        dataTable.editable = false;
                                    } else {
                                        // unlock
                                        $(node)
                                            .removeClass('btn-warning')
                                            .addClass('btn-danger')
                                            .find('i')
                                            .removeClass('fa-lock')
                                            .addClass('fa-unlock');
                                        dataTable.editable = true;
                                    }

                                    if (!dataTable.blockUI) {
                                        dataTable.blockUI = true;
                                        dataTable.toggleBlockUI = true;
                                    }

                                    dataTable.redrawPage();
                                }
                            }
                        }
                        break;
                    case 'check_all':
                    case 'select_all':
                        if (typeof dataTable.customButtons[obj] === 'undefined') {
                            if (typeof label === 'undefined')
                                label = (typeof datatable_lang.customButton[obj] !== 'undefined') ? datatable_lang.customButton[obj] : 'Seleziona tutti';

                            if (typeof cls === 'undefined')
                                cls = 'btn-info';

                            if (typeof icon === 'undefined')
                                icon = 'fa-check';

                            if (typeof tooltip === 'undefined')
                                tooltip = (typeof datatable_lang.customButton.tooltip[obj] !== 'undefined') ? datatable_lang.customButton.tooltip[obj] : '';

                            dataTable.customButtons[obj] = {
                                text: '<i class="fa ' + icon + '"></i> <span data-checked="0">' + label + '</span>',
                                className: "btn btn-white btn-xs btn-margin-right " + cls,
                                titleAttr: tooltip,
                                action: function (e, table, node, config) {
                                    var $span = $(node).find('[data-checked]');
                                    var checked = parseInt($span.attr('data-checked'));
                                    if (checked) {
                                        dataTable.$table.find("[data-interaction=check]:checked").click();
                                        $span.attr('data-checked', '0');
                                        $span.html(label);
                                    } else {
                                        dataTable.$table.find("[data-interaction=check]").not(':checked').click();
                                        $span.attr('data-checked', '1');
                                        $span.html(typeof datatable_lang.customButton['uncheck_all'] !== 'undefined' ? datatable_lang.customButton['uncheck_all'] : 'Deseleziona tutti');
                                    }
                                }
                            }
                        }
                        break;
                }
            });
        }

        this.afterMakeCustomButtons();
    };

    this.afterMakeCustomButtons = function () {
    };

    this.makeDT = function () {
        $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
            app.error("", message);
        };

        var language = typeof datatable_lang !== 'undefined' ? datatable_lang : {
            "sEmptyTable": "Nessun dato presente nella tabella",
            "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
            "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
            "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "_MENU_",
            "sLoadingRecords": "Caricamento...",
            "sProcessing": "Elaborazione...",
            "sSearch": "Cerca:",
            "sZeroRecords": "La ricerca non ha portato alcun risultato.",
            "oPaginate": {
                "sFirst": "Inizio",
                "sPrevious": "Precedente",
                "sNext": "Successivo",
                "sLast": "Fine"
            },
            "select": {
                "rows": {
                    "_": "You have selected %d rows",
                    "0": "Click a row to select it",
                    "1": "Only 1 row selected"
                }
            },
            "oAria": {
                "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
                "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
            }
        };

        // imposto la width delle colonne così come vengono create all'inizio così da avere la gestione della width lato controller
        dataTable.columnDefs = [];
        var index = 0;

        // width dei bottoni inline
        if (dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]")) {
            dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]").css({
                'min-width': dataTable.nInlineButton * 25 + "px",
                'width': dataTable.nInlineButton * 25 + "px"
            });
            index++;
        }

        // fisso le colonne in modo che usando i filtri queste non si ridimensionano
        dataTable.$table.find("thead").find("tr").find('[data-dt-filter]').each(function () {
            dataTable.columnDefs.push({
                width: $(this).width(),
                targets: index++,
                createdCell: dataTable.DTfnCreatedCell
            });
        });

        var options = {
            dom: "<'row'<'col-sm-12'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-7'li><'col-sm-5'p>>",
            bAutoWidth: false,
            aoColumns: dataTable.columns,
            columnDefs: dataTable.columnDefs,
            displayStart: parseInt(dataTable.displayStart),
            pageLength: parseInt(dataTable.pageLength),
            searchCols: dataTable.searchCols,
            order: dataTable.order,
            aaSorting: [],
            info: dataTable.info,
            aLengthMenu: dataTable.lenghtMenu,
            bLengthChange: true,
            pagingType: "extStyle", // TODO: commentare per paginazione originale
            bPaginate: dataTable.pagination,
            select: dataTable.select,
            responsive: {
                details: false
            },

            // language: prende la variabile globale datatable_lang oppure valori di default
            language: language,

            // gestione server side ajax
            processing: false,
            deferRender: true,
            serverSide: (typeof dataTable.ajaxUrl !== 'undefined'),
            ajax: {
                url: (typeof dataTable.ajaxUrl !== 'undefined') ? dataTable.ajaxUrl : false,
                type: 'POST'
            },

            footerCallback: dataTable.footerCallback,
            headerCallback: dataTable.headerCallback,
            fnDrawCallback: dataTable.DTfnDrawCallback,
            initComplete: dataTable.DTinitComplete,
            fnCreatedRow: dataTable.DTfnCreatedRow
        };

        if (typeof dataTable.fixedColumns != "undefined") {
            options.responsive = false;
            options.scrollX = true;
            options.scrollCollapse = true;
            options.fixedColumns = dataTable.fixedColumns;
        }

        if (typeof dataTable.scrollY !== 'undefined') {
            // TODO: quando è attivo lo scroll la DT non si ridimensiona più!
            $.extend(options, dataTable.scrollY);
        }

        var initData = undefined;

        if (dataTable.pagination) {
            // pipeline
            if (dataTable.pipeline) {
                var cacheLower;
                var cacheUpper;
                var cacheLastRequest;

                if (dataTable.initData) {
                    initData = JSON.parse(dataTable.initData);

                    cacheLower = parseInt(dataTable.displayStart);
                    cacheUpper = parseInt(dataTable.displayStart) + parseInt(dataTable.pageLength) * parseInt(dataTable.pipeline);

                    // compilo la prima richiesta
                    cacheLastRequest = {
                        columns: [],
                        order: [],
                        search: {
                            value: "",
                            regex: false
                        }
                    };
                    $.each(dataTable.searchCols, function (index) {
                        var obj = {
                            data: typeof dataTable.columns[index].data !== 'undefined' ? dataTable.columns[index].data : index,
                            name: "",
                            searchable: true,
                            orderable: typeof dataTable.columns[index].orderable !== 'undefined' ? dataTable.columns[index].orderable : false,
                            search: {
                                value: "",
                                regex: false
                            }
                        };

                        obj.search.value = typeof this.search !== 'undefined' ? this.search : "";
                        obj.search.regex = typeof this.bRegex !== 'undefined' ? this.bRegex : false;

                        cacheLastRequest.columns.push(obj);
                    });

                    $.each(dataTable.order, function () {
                        cacheLastRequest.order.push({
                            column: this[0],
                            dir: this[1]
                        });
                    });
                }

                options.ajax = $.fn.dataTable.pipeline({
                    url: dataTable.ajaxUrl,
                    method: 'POST',
                    pages: dataTable.pipeline,
                    deferLoading: !!dataTable.initData,
                    cacheLower: cacheLower,
                    cacheUpper: cacheUpper,
                    initData: initData,
                    cacheLastRequest: cacheLastRequest,
                    data: {
                        _pipeline: 1
                    }
                });

                if (dataTable.sessionEnabled) {
                    // restore della pagina e del length dalle preferenze
                    // attenzione! magari ho delle preferenze sballate rispetto alla sessione
                    var start = parseInt(preference.get(dataTable.idTable + ".displayStart"));
                    var length = parseInt(preference.get(dataTable.idTable + ".pageLength"));

                    // controllo che lo start sia all'interno della pipeline
                    if (start >= dataTable.displayStart && start <= (parseInt(dataTable.displayStart) + (parseInt(dataTable.pageLength) * parseInt(dataTable.pipeline))))
                        options.displayStart = parseInt(start);
                    else
                        preference.set(dataTable.idTable + ".displayStart", null);

                    // controllo che il length sia all'interno della pipeline
                    if (length != null && length <= dataTable.pageLength * dataTable.pipeline)
                        options.pageLength = parseInt(length);
                    else
                        preference.set(dataTable.idTable + ".pageLength", null);
                }
            }
        }

        // deferLoading
        if (dataTable.initData) {
            initData = JSON.parse(dataTable.initData);
            var ret = initData.data;

            if (dataTable.pagination && dataTable.pipeline) {
                ret.splice(0, options.displayStart - dataTable.displayStart);
                ret.splice(options.pageLength, initData.data.length);
            }

            options.data = ret;
            options.deferLoading = initData.recordsFiltered;
        }

        // DATATABLE
        dataTable.table = dataTable.$table.DataTable(options);

        // Plugin col resize
        if (typeof $.fn.colResizable !== "undefined" && !dataTable.fixedHeader)
            dataTable.$table.colResizable();

        // info
        if (!options.info && !options.bPaginate) {
            $("#" + dataTable.idTable + "_wrapper").find('.row:last-child').hide();
        }

        if (dataTable.sessionEnabled && dataTable.pipeline) {
            dataTable.table.on('length.dt', function () {
                preference.set(dataTable.idTable + '.pageLength', parseInt(dataTable.table.page.info().length));
                preference.set(dataTable.idTable + '.displayStart', parseInt(dataTable.table.page.info().start));
            });

            dataTable.table.on('page.dt', function () {
                preference.set(dataTable.idTable + '.displayStart', parseInt(dataTable.table.page.info().start));
            });
        }

        if (dataTable.fixedHeader) {
            new $.fn.dataTable.FixedHeader(dataTable.table);
        }

        var buttons = [];
        var custom = $.extend([], dataTable.customButtons);
        var buttonsLength = Object.keys(custom).length;

        var buttonsOrder = dataTable.$table.attr("data-dt-custom-button");
        // TODO: aggiornare codice per ordinamento custom buttons -> prevedere oltre alla string con pipe (per retrocompatibilità) anche array di array (versione nuova)
        $.each(buttonsOrder.split('|'), function (i, v) {
            if (typeof custom[v] !== 'undefined') {
                buttons.push(custom[v]);
                delete custom[v];
            }
        });

        $.each(Object.keys(custom), function (index, obj) {
            buttons.push(custom[obj]);
        });

        if (dataTable.csv) {
            buttons.push({
                extend: "csvHtml5",
                text: "<i class='fa fa-database bigger-110 orange'></i> <span class='hidden'>Export to CSV</span>",
                className: "btn btn-white btn-primary btn-xs",
                fieldBoundary: "",
                charset: false,
                footer: true,
                fieldSeparator: ";",
                customize: eval(dataTable.exportCallback)
            });
        }

        if (dataTable.pdf) {
            if (typeof dataTable.ajaxUrl !== 'undefined') {

            } else {
                buttons.push({
                    extend: "pdf",
                    text: "<i class='fa fa-print bigger-110 grey'></i> <span class='hidden'>Print</span>",
                    className: "btn btn-white btn-primary btn-xs",
                    autoPrint: false,
                    footer: true,
                    orientation: 'landscape',
                    title: title,
                    customize: eval(dataTable.printCallback)
                });
            }
        }

        // rimuovo dall'ultimo button il margin a destra
        if (typeof buttons[buttons.length - 1] !== 'undefined' && typeof buttons[buttons.length - 1].className !== 'undefined')
            buttons[buttons.length - 1].className = buttons[buttons.length - 1].className.replace("btn-margin-right", "");

        if (dataTable.csv || dataTable.pdf || buttonsLength) {
            new $.fn.dataTable.Buttons(dataTable.table, {
                buttons: buttons
            });
            dataTable.table.buttons().container().appendTo($("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter'));
        }

        if (typeof dataTable.ajaxUrl !== 'undefined') {
            $("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter').find('label').hide();
            $("#" + dataTable.idTable + "_wrapper").find('.row:first-child').find('.col-sm-6:first-child').removeClass('col-sm-6').addClass('col-sm-3');
            $("#" + dataTable.idTable + "_wrapper").find('.row:first-child').find('.col-sm-6').removeClass('col-sm-6').addClass('col-sm-9');
        }

        dataTable.$table.trigger('makeDtCompleted');
    };

    this.getAllData = function (success, error) {
        var params = dataTable.table.ajax.params();
        if (typeof params == "undefined")
            params = {};
        params.dt = dataTable.configuration;
        params.dt_session = dataTable.dt_session;
        params.start = 0;
        params.length = -1;

        $.post(dataTable.ajaxUrl, params)
            .done(function (data) {
                if (typeof success == 'function')
                    success(data);
            })
            .fail(function (data) {
                if (typeof error == 'function')
                    error(data);
            });
    };

    this.headerCallback = function (thead, data, start, end, display) {
        var $tr = dataTable.$table.find('thead').find('tr:not([data-dt-filter=1])');
        $tr.find('th').each(function () {
            var inline = parseInt($(this).attr('data-dt-inline-button'));
            if (!inline) {
                var tooltip = $(this).attr('data-tooltip');
                if (tooltip) {
                    tooltip = JSON.parse(tooltip);
                    if (typeof tooltip.text !== 'undefined' && typeof $(this).attr("tooltip-append-to-body") === "undefined") {
                        $(this).attr("title", tooltip.text).attr("tooltip-append-to-body", true).tooltip({
                            container: 'body',
                            placement: 'bottom'
                        });
                        $(this).append(' <i class="fa fa-question-circle"></i>');
                    }
                }
            }
        });
    };

    this.footerCallback = function (row, data, start, end, display) {
        // visualizzo i totali se ci sono e se sono attivati
        var json = dataTable.initData ? JSON.parse(dataTable.initData) : undefined;
        if (typeof dataTable.table !== 'undefined')
            json = dataTable.table.ajax.json();

        if (typeof json !== 'undefined' && dataTable.totals && typeof json.totals !== 'undefined' && json.totals.length > 0) {
            var $tfoot = this.find('tfoot').length ? this.find('tfoot') : $('<tfoot></tfoot>');

            var $tr = $('<tr></tr>');

            // aggiungo una cella fittizzia per gli inlineButtons
            if (dataTable.inlineButtons)
                $tr.append('<td></td>');

            $.each(json.totals, function (i, v) {
                // risalgo al th della colonna
                var $th = dataTable.$table.find('[data-dt-column-index=' + i + ']');
                var total = $th.attr('data-dt-total');
                var align = $th.attr('data-dt-align');
                var a = 'left';

                if (typeof align !== 'undefined' && $.trim(align) != '') {
                    a = align;
                }

                if (typeof total !== 'undefined' && $.trim(total) != '') {
                    total = JSON.parse(total);
                    if (typeof total.align !== 'undefined')
                        a = total.align;
                }

                var symbol = '';

                // TODO valorizzare il simbolo in base all'espressione
                // switch (1) {
                //     case 'sum':
                //         symbol = '∑';
                //         break;
                //     case 'avg':
                //         symbol = 'μ';
                //         break;
                //     default:
                //         symbol = '∑';
                // }

                var $content = (typeof v !== 'undefined') ? ((v.length > 0) ? '<h4>' + symbol + ' ' + v + '</h4>' : '&nbsp;') : '&nbsp;';
                var $td = $('<td data-index="' + i + '" style="text-align: ' + a + '">' + $content + '</td>');

                $tr.append($td);
            });

            $tfoot.html($tr);
            $tfoot.insertBefore($(this).find('tbody'));
        }
    };

    this.beforeSuccessEditable = function (data, field, id, columnIndex, rowIndex, col, editable) {
        if (editable.$tr.data('add_client')) {
            return false;
        }

        return true;
    };

    this.afterSuccessEditable = function (data, field, id, columnIndex, rowIndex, col, $tr) {
    };

    this.beforeErrorEditable = function (data, field, id, columnIndex, rowIndex, col, $tr) {
        return true;
    };

    this.afterErrorEditable = function (data, field, id, columnIndex, rowIndex, col, $tr) {
    };

    this.extraParamsEditable = function (index, id, columnIndex, rowIndex, col) {
        return {};
    };

    this.newRow = function ($tr, data, editing) {
        if ($tr.find('[data-interaction=edit_default]').length == 0) {
            $tr.find("td").each(function (index) {
                var colIndex = dataTable.inlineButtons ? index : index + 1;

                if (typeof dataTable.columns[index].createdCell !== 'undefined' && typeof data[colIndex] !== 'undefined') {
                    dataTable.columns[index].createdCell($(this), data[colIndex], data, dataTable.table.row($tr).index(), colIndex);
                } else {
                    dataTable.DTfnCreatedCell($(this), data[index], data, dataTable.table.row($tr).index(), colIndex);
                }
            });
        }

        dataTable.DTfnCreatedRow($tr, data);
        dataTable.DTfnDrawCallback(dataTable.DrawCallbackSettings, function () {
            if (editing && $tr.find('[data-interaction=edit_default]').length > 0) {
                $tr.find('[data-interaction=edit_default]').trigger('click');
            }
        });
    };

    this.redraw = function (nextFocus, $tr, data, editing) {
        nextFocus = nextFocus || false;

        // prima di fare il redraw, mi salvo quale elemento in questo momento ha il focus così da ripristinarlo
        // in caso sia un elemento all'interno dell'attuale DataTable
        var $focus = $(document.activeElement);
        if (dataTable.table.cell($focus.closest('td')) && nextFocus) {
            dataTable.focusAfterDraw = dataTable.table.cell($focus.closest('td')).index();
        }

        if (nextFocus == 'row' || editing) {
            if ($tr.data('add_first')) {
                $tr.data('data', data);
                $tr.find('td').each(function (i) {
                    var val = data[i];
                    if (i == 0 && dataTable.inlineButtons) {
                        val = dataTable.columns[0].defaultContent;
                    }
                    if (val == null)
                        val = "";
                    $(this).html(val);
                });
            } else {
                dataTable.table.row($tr).data(data);
            }
            dataTable.newRow($tr, data, editing);
            if (nextFocus != false && nextFocus != 'row')
                dataTable.redrawPage();
        } else {
            dataTable.redrawPage();
        }
    };

    this.DTBeforefnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
        return true;
    };

    this.DTAfterfnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
    };

    this.DTfnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
        if (!dataTable.DTBeforefnCreatedCell(td, data, rowData, rowIndex, columnIndex))
            return;

        var id = rowData[0];

        var col = dataTable.columns[columnIndex];

        // assegno l'uid della colonna alla cella
        $(td).attr('data-uid', col.uid);

        // assegno l'index della colonna
        $(td).attr('data-index', columnIndex);

        // aggiungo un title al td
        if (col.tooltipCell)
            $(td).attr("title", data);

        if (typeof col.editable !== 'undefined' && col.editable && dataTable.editable != false) {
            var editing = parseInt($(td).closest("tr").find('[data-interaction=edit_default]').attr('data-editing'));

            var index = col.columnIndex;
            var url = col.editable.url || dataTable.ajaxEditUrl;
            var spinner = true;
            if (typeof col.editable.spinner != "undefined" && col.editable.spinner == false)
                spinner = false;

            var value = $(td).html();
            if (typeof $(td).attr('data-value-to-use') !== 'undefined') {
                value = $(td).attr('data-value-to-use');
            }

            switch (col.editable.type) {
                case 'select':
                    var autocomplete = "";
                    if (col.editable.autocomplete_url) {
                        autocomplete = col.editable.autocomplete_url;
                    } else {
                        if (typeof dataTable.filterColumns[columnIndex] !== 'undefined' && typeof dataTable.filterColumns[columnIndex].filter !== 'undefined' && typeof dataTable.filterColumns[columnIndex].filter.url !== 'undefined')
                            autocomplete = dataTable.getUrlWithParameters(dataTable.filterColumns[columnIndex].filter.url);
                        else
                            autocomplete = dataTable.ajaxUrl;

                        autocomplete = autocomplete + "&op=autocomplete&dt_editing=1&dt_col_index=" + index;
                    }
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'select',
                        bind: 'select2:select',
                        id: id,
                        url: url,
                        nullable: ((typeof col.editable.nullable !== 'undefined') ? col.editable.nullable : true),
                        nullValue: ((typeof col.editable.null_value !== 'undefined') ? col.editable.null_value : null),
                        autocomplete: autocomplete,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        tags: col.editable.tags,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'green');
                                    $element.attr('data-old-value', $element.val());
                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                    $element.val($element.attr('data-old-value')).trigger('change');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                app.error("", "Errore!");
                                $element.val($element.attr('data-old-value')).trigger('change');
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
                case 'bool':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'bool',
                        bind: 'click',
                        id: id,
                        url: url,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                if (data.response) {
                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                app.error("", app.parseAjaxError(data));
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
                case "datetime":
                case "date":
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: col.editable.type,
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        date_format: col.editable.date_format,
                        datetime_format: col.editable.datetime_format,
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');

                                app.error("", app.parseAjaxError(data));
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
                case 'textarea':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'textarea',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        spinner: spinner,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');

                                app.error("", app.parseAjaxError(data));
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
                case 'simpletextarea':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'simpletextarea',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        spinner: spinner,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');

                                app.error("", app.parseAjaxError(data));
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
                default:
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'text',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: value,
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw || editing) {
                                        dataTable.redraw(col.editable.redraw, $(td).closest("tr"), data.message, editing);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col, this);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col, this)) {
                                if (spinner)
                                    $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');

                                app.error("", app.parseAjaxError(data));
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col, this);
                        }
                    }).make();
                    break;
            }
        }

        dataTable.DTAfterfnCreatedCell(td, data, rowData, rowIndex, columnIndex);
    };

    this.redrawPage = function () {
        dataTable.table.clearPipeline().draw('page');
    };

    this.make = function () {
        dataTable.makeColumns();
        dataTable.makeFilters();
        dataTable.makeSorting();
        dataTable.makeCustomButtons();
        dataTable.makeDT();

        // inserisco in data il mio oggetto
        dataTable.$table.data('dataTable', dataTable);
    };
};